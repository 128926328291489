<template>
  <div class="UxDesktopSideBar">
    <div class="UxDesktopSideBar__Types">
      <div
        class="screenAndCameraRecording"
        v-if="screenRecording || cameraRecording"
      >
        <span
          v-if="screenRecording"
          class="screenAndCameraRecording__Dot"
        ></span>
        <svg
          v-if="cameraRecording"
          class="screenAndCameraRecording__Icon"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 21V19.93C9.33387 19.6891 7.81031 18.856 6.7084 17.5833C5.60648 16.3106 4.99998 14.6835 5 13V8C5 7.08075 5.18106 6.1705 5.53284 5.32122C5.88463 4.47194 6.40024 3.70026 7.05025 3.05025C7.70026 2.40024 8.47194 1.88463 9.32122 1.53284C10.1705 1.18106 11.0807 1 12 1C12.9193 1 13.8295 1.18106 14.6788 1.53284C15.5281 1.88463 16.2997 2.40024 16.9497 3.05025C17.5998 3.70026 18.1154 4.47194 18.4672 5.32122C18.8189 6.1705 19 7.08075 19 8V13C19 14.6835 18.3935 16.3106 17.2916 17.5833C16.1897 18.856 14.6661 19.6891 13 19.93V21H17V23H7V21H11ZM12 3C10.6739 3 9.40215 3.52678 8.46447 4.46447C7.52678 5.40215 7 6.67392 7 8V13C7 14.3261 7.52678 15.5979 8.46447 16.5355C9.40215 17.4732 10.6739 18 12 18C13.3261 18 14.5979 17.4732 15.5355 16.5355C16.4732 15.5979 17 14.3261 17 13V8C17 6.67392 16.4732 5.40215 15.5355 4.46447C14.5979 3.52678 13.3261 3 12 3V3ZM12 9C12.2652 9 12.5196 8.89464 12.7071 8.70711C12.8946 8.51957 13 8.26522 13 8C13 7.73478 12.8946 7.48043 12.7071 7.29289C12.5196 7.10536 12.2652 7 12 7C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11 8C11 8.26522 11.1054 8.51957 11.2929 8.70711C11.4804 8.89464 11.7348 9 12 9V9ZM12 11C11.2044 11 10.4413 10.6839 9.87868 10.1213C9.31607 9.55871 9 8.79565 9 8C9 7.20435 9.31607 6.44129 9.87868 5.87868C10.4413 5.31607 11.2044 5 12 5C12.7956 5 13.5587 5.31607 14.1213 5.87868C14.6839 6.44129 15 7.20435 15 8C15 8.79565 14.6839 9.55871 14.1213 10.1213C13.5587 10.6839 12.7956 11 12 11Z"
            fill="currentColor"
          />
        </svg>
        <span><i class="fas fa-microphone"></i></span>
        {{ $t('WatUX.recording') }}
      </div>
      <br />
      <h4>{{ $t('WatUX.checkpoints.title') }}</h4>
      <br />
      <ul class="UxDesktopSideBar__Types__Tasks">
        <li v-for="checkpoint in checkpoints" :key="checkpoint.checkId">
          <span
            class="Checkpoint__Icon"
            :class="
              checkpoint.completed
                ? 'Checkpoint__Icon--completed'
                : 'Checkpoint__Icon--incomplete'
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              v-if="!checkpoint.completed"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
              width="24"
              height="24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              v-else
              viewBox="0 0 20 20"
              fill="currentColor"
              width="24"
              height="24"
            >
              <path
                fill-rule="evenodd"
                d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
          <span class="Checkpoint__Text">{{ checkpoint.message }}</span>
        </li>
      </ul>
      <button
        type="button"
        name="button"
        @click="$emit('sendToQuestions')"
        class="WatUX-button"
      >
        {{ $t('goquestion_mode') }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UxDesktopSideBar',
  props: {
    checkpoints: {
      type: Array,
      default: null
    },
    screenRecording: {
      type: Boolean,
      default: false,
    },
    cameraRecording: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss">
.UxDesktopSideBar {
  display: none;
  @media only screen and (min-width: 770px) {
    display: block;
  }
  order: 2;
  &__Types {
    font-size: 14px;
    font-weight: bold;
    color: #404040;
    padding: 15px 0;
    border-top: 1px solid lightgray;
    p {
      margin: 0;
    }
    .testType {
      color: var(--secondary-color);
    }
    .companyName {
      color: #8134ff;
    }
    .screenAndCameraRecording {
      padding: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      border-radius: 2px;
      background-color: #fff;
      box-shadow: 0.1rem 0.1rem 0rem rgba(#000, 0.07);
      border: 1px solid rgba(#000, 0.1);
      font-size: 0.8rem;
      &__Dot {
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 9999px;
        background-color: hsl(0, 86%, 49%);
        margin-right: 0.5rem;
        display: block;
        animation: blink 1s ease-in-out infinite;
        box-shadow: 0 0 0 1px #fff, 0 0 0 2px hsl(0, 86%, 49%);
        @keyframes blink {
          0% {
            opacity: 0;
          }
          50% {
            opacity: 100%;
          }
          100% {
            opacity: 0;
          }
        }
      }
      &__Icon {
        color: hsl(0, 86%, 49%);
      }
      i {
        color: hsl(0, 86%, 49%);
        font-size: 17px;
        margin: 0 0.5rem;
        padding-top: 2px;
      }
    }
    &__Tasks {
      list-style: none;
      max-height: 200px;
      overflow-y: auto;
      padding: 2px 0;
      &::-webkit-scrollbar-track {
        background-color: #e6e6e6;
      }
      &::-webkit-scrollbar {
        background-color: #8134ff;
        width: 3px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: var(--main-color);
      }
      li {
        padding: 0.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-radius: 2px;
        background-color: #fff;
        box-shadow: 0.1rem 0.1rem 0rem rgba(#000, 0.07);
        border: 1px solid rgba(#000, 0.1);
        font-size: 0.8rem;
        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
        .Checkpoint__Text{
            flex: 1;
        }
      }
    }
    .WatUX-button {
      background: var(--button-color);
      font-family: 'Bariol';
      font-weight: 600;
      font-size: 16px;
      border-radius: 3px;
      border: none;
      padding: 0.8rem 1rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: white;
      margin: 1.5rem 0 1rem;
      width: 100%;
      cursor: pointer;
      &:disabled {
        opacity: 0.5;
      }
    }
  }
}
</style>
